import { nextTick } from '#imports'

export const useDropdownDirection = (): Record<
  'y',
  (element: HTMLElement) => Promise<void>
> => {
  return {
    y: async (element) => {
      await nextTick()

      if (!(element instanceof HTMLElement)) return

      element.dataset.position = 'bottom'

      const { top, height } = element.getBoundingClientRect()

      if (top + height > window.innerHeight) {
        element.dataset.position = 'top'

        const { top } = element.getBoundingClientRect()

        if (top < 0) element.dataset.position = 'bottom'
      }
    },
  }
}
