<template>
  <Transition
    :enter-from-class="$style.dropdown_invisible"
    :enter-active-class="$style.dropdown_active"
    :leave-to-class="$style.dropdown_invisible"
    :leave-active-class="$style.dropdown_active"
  >
    <div v-if="isOpened" ref="rootRef" :class="$style.dropdown">
      <slot />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { ref, watch, nextTick } from '#imports'
import { useDropdownDirection } from '~/composables/useDropdownDirection'

const props = defineProps<{ isOpened: boolean }>()

const rootRef = ref<HTMLElement>()

const dropdownDirection = useDropdownDirection()

watch(
  () => props.isOpened,
  async (isOpened) => {
    await nextTick()

    if (!isOpened || !rootRef.value) return

    dropdownDirection.y(rootRef.value)
  }
)
</script>

<style lang="scss" module>
.dropdown {
  position: absolute;
  border-radius: 8px;
  overflow: auto;
  background: #ffffff;

  &[data-position='bottom'] {
    box-shadow: 0px 11px 33px rgba(0, 0, 0, 0.2);
  }

  &[data-position='top'] {
    box-shadow: 0px -11px 33px rgba(0, 0, 0, 0.2);
  }

  &_invisible {
    opacity: 0;
  }

  &_active {
    transform: opacity 0.3s;
  }
}
</style>
